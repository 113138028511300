<template>
  <div>
    <div v-for="(item, index) in list" v-bind:key="index">
      <div v-if="item.communication_type_id === 3">
        <b-row>
          <b-col class="">
            <div class="title-msg">Reply</div>
          </b-col>
          <b-col class="text-right">
            <b-button
              class="title-msg pointer txt-underline"
              @click.prevent="onAddResponse(index)"
              v-if="item.detail_list.length < 5"
              >Add</b-button
            >
            <b-button
              class="text-secondary-color txt-underline"
              v-else
            ></b-button>
          </b-col>
        </b-row>

        <div v-for="(line, lindex) in item.detail_list" :key="lindex">
          <div class="reply-box p-2">
            <div class="py-2">
              <b-row class="my-2">
                <b-col class="reply-text">Reply # {{ lindex + 1 }}</b-col>
                <b-col class="text-right" v-if="item.detail_list.length > 1">
                  <b-button @click.prevent="onDeleteResponse(index, lindex)">
                    <font-awesome-icon
                      icon="trash-alt"
                      class="pointer"
                      color="#000"
                    />
                  </b-button>
                </b-col>
              </b-row>
              <div class="mt-3">
                <ResponseForm
                  ref="reponseForm"
                  v-if="isLoading"
                  :line="line"
                  :vSortOrder="
                    v.$each.$iter[index].detail_list.$each.$iter[lindex]
                      .sort_order
                  "
                  :v="
                    v.$each.$iter[index].detail_list.$each.$iter[lindex]
                      .communication_value
                  "
                  :vFormat="
                    v.$each.$iter[index].detail_list.$each.$iter[lindex]
                      .format_list
                  "
                  :isShowSidebar="isShowSidebar"
                  :sortOrder="lindex"
                  :replyTypeList="messageType"
                  :dataImage="dataImage"
                  :id="id"
                  :formMain="formMain"
                  :variableItem="variableItem"
                  :variableCampaignList="variableCampaignList"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ResponseForm from "@/components/automation/detail/LINE/ResponseForm";
export default {
  components: {
    ResponseForm,
  },
  props: {
    messageType: {
      required: true,
      type: Array,
    },
    list: {
      required: true,
      type: Array,
    },
    v: {
      required: false,
      type: Object,
    },
    checked: {
      required: false,
      type: Array,
    },
    dataImage: {
      required: true,
      type: Array,
    },
    id: {
      required: false,
      type: String,
    },
    isShowSidebar: {
      required: false,
      type: Boolean,
    },
    variableItem: {
      required: true,
      type: Array,
    },
    variableCampaignList: {
      required: true,
      type: Array,
    },
    formMain: {
      required: false,
    },
  },
  data() {
    return {
      isLoading: true,
    };
  },
  methods: {
    handleChangeType(value, index, rindex) {
      this.list.intent[index].neutralReply[rindex].content.ReplyType = value;
    },
    onAddResponse(index) {
      let reply = {
        message_type_id: "10",
        communication_value: [
          {
            message_type_id: 10,
            value: "",
            image_url: "",
          },
        ],
        format_list: [],
        sort_order: 0,
      };
      reply.sort_order = this.list[index].detail_list.length + 1;
      this.list[index].detail_list.push(reply);
    },
    onDeleteResponse(intentIndex, replyIndex) {
      this.isLoading = false;
      this.list[intentIndex].detail_list.splice(replyIndex, 1);
      this.list[intentIndex].detail_list.forEach((element, index) => {
        element.sort_order = index + 1;
      });
      this.isLoading = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.reply-box {
  border-radius: 5px;
  background-color: var(--theme-secondary-color);
  border-color: var(--theme-secondary-color);
  margin-bottom: 10px;
}
.title-msg {
  color: #000;
  font-size: 16px;
  font-weight: 600;
  padding: 0.375rem 0;
}
.reply-text {
  color: var(--primary-color);
  font-size: 16px;
}
::v-deep .select-custom > label {
  font-size: 16px !important;
}
.remark {
  color: red;
}
.title-type {
  color: #000;
  font-size: 16px;
  font-weight: 600;
}
.txt-underline {
  text-decoration: underline;
  border: none;
  background-color: transparent;
}
::v-deep .btn-secondary {
  background-color: transparent !important;
  border-color: transparent !important;
}
</style>
