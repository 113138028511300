<template>
  <div>
    <b-row>
      <b-col md="12" class="hide-label">
      <InputTextAreaVariable
          :reference="'textarea'"
          class="mb-4"
          type="text"
          textFloat="Description"
          placeholder=""
          name="text"
          rows="5"
          isRequired
          :isValidate="v.value.$error"
          :v="v.value"
          :response="response"
          :formMain="formMain"
          v-model="response.value"
          :variableItem="variableItem"
          :variableCampaignList="variableCampaignList"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import InputTextAreaVariable from "@/components/inputs/InputTextAreaVariable";

export default {
  components: {
    InputTextAreaVariable,
  },
  props: {
    response: {
      required: true,
      type: Object,
    },
    v: {
      required: false,
      type: Object,
    },
    variableItem: {
      required: true,
      type: Array,
    },
    variableCampaignList: {
      required: true,
      type: Array,
    },
    formMain: {
      required: false,
    },
  },
};
</script>

<style lang="scss" scoped></style>
