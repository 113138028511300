<template>
  <div>
    <b-row>
      <b-col md="12" class="hide-label">
        <InputTextAreaVariable
          :reference="'textarea'"
          type="text"
          textFloat="Description"
          placeholder=""
          name="text"
          rows="5"
          :isValidate="v.value.$error"
          :v="v.value"
          v-model="text.value"
          :response="text"
          :variableItem="variableItem"
          :variableCampaignList="variableCampaignList"
          :formMain="formMain"
        />
      </b-col>
      <b-col md="12">
        <span class="title-img">Image <small>(Ratio 1:1)</small></span>
      </b-col>
      <b-col md="12">
        <ImageUpload
          class="mt-1"
          :v="v.image_url"
          v-bind:dataFile="text.image_url"
          @handleChangeImage="handleChangeImage"
          :isRatio="true"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import InputTextAreaVariable from "@/components/inputs/InputTextAreaVariable";
import UploadFile from "@/components/inputs/UploadFile";
import ImageUpload from "@/components/automation/detail/LINE/TypeMessage/ImageUpload";
export default {
  components: {
    ImageUpload,
    InputTextAreaVariable,
    UploadFile,
  },
  props: {
    response: {
      required: true,
      type: Object,
    },
    v: {
      required: false,
      type: Object,
    },
    text: {
      required: true,
      type: Object,
    },
    variableItem: {
      required: true,
      type: Array,
    },
    variableCampaignList: {
      required: true,
      type: Array,
    },
    formMain: {
      required: false,
    },
  },
  data() {
    return {
      respLine: this.response,
    };
  },
  methods: {
    handleChangeImage(image) {
      this.text.image_url = image;
    },
    AddVariable(variable) {
      const insertText = variable.name;
      if (!insertText.length) return;
      const textarea = this.$refs.textarea;
      const sentence = textarea.value;
      const len = sentence.length;
      let pos = textarea.selectionStart;
      if (pos === undefined) {
        pos = 0;
      }
      const before = sentence.substr(0, pos);
      const after = sentence.substr(pos, len);
      this.text.value = before + `{{${insertText}}}` + after;
      this.$nextTick().then(() => {
        textarea.selectionStart = pos + insertText.length;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.title-img {
  color: #16274a;
  font-size: 17px;
  margin-bottom: 2px;
  font-weight: bold;
}
</style>
